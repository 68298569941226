.hoz-flex-item-one {
  flex: 1;
}

.hoz-flex-item-two {
  flex: 2 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
}

.hoz-flex-item-three {
  flex: 1;
}

.round {
  font-size: 14px;
  color: #353d49;
  line-height: 17px;

  margin-bottom: 0;
}
