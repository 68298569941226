.leaderboard-container {
  padding: 15px 9px 6rem 9px;
  box-sizing: border-box;
  z-index: -1;
}

.leaderboard-back-button {
  position: absolute;
  left: 1.2rem;
  top: 6vw;
  border: none;
  background-color: transparent;
}

.leaderboard-period-picker {
  background-color: white;
  height: 2.9rem;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  padding: 0 1.7rem;
  color: #afafaf;
  font-weight: 500;
}

@media all and (min-width: 768px) {
  .leaderboard-period-picker {
    width: 600px;
    margin: 0 auto;
  }
}
@media all and (min-width: 1200px) {
  .lealeaderboard-period-picker {
    width: 900px;
    margin: 0 auto;
  }
}

.leaderboard-period-picker_btn {
  border: none;
  background-color: transparent;
  color: inherit;
  flex: 1;
  font-weight: inherit;
}

.leaderboard-period-picker_btn.selected {
  color: #79c000;
}

.leaderboard-period-picker_arrow {
  font-size: 0.6rem;
  padding-left: 0.8rem;
}

.leaderboard-period-picker_option {
  border: none;
  background-color: transparent;
  color: inherit;
  font-weight: inherit;
  padding: 0.6rem;
}

.leaderboard-subtitle {
  text-align: center;
  padding: 1.2rem 0;
  font-weight: 500;
  font-size: 1rem;
}

@media all and (min-width: 768px) {
  .leaderboard-subtitle {
    width: 600px;
    margin: 0 auto;
  }
}
@media all and (min-width: 1200px) {
  .leaderboard-subtitle {
    width: 900px;
    margin: 0 auto;
  }
}

.leaderboard-table {
  background-color: white;
  border-radius: 7px;
  font-weight: 500;
}

.leaderboard-table-main {
  padding: 1rem 1.6rem;
}

@media all and (min-width: 768px) {
  .leaderboard-table-main {
    width: 600px;
    margin: 0 auto;
  }
}
@media all and (min-width: 1200px) {
  .leaderboard-table-main {
    width: 900px;
    margin: 0 auto;
  }
}

.leaderboard-my-rank {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.4rem 1.6rem;
}

@media all and (min-width: 768px) {
  .leaderboard-my-rank {
    width: 600px;
    margin: 0 auto;
  }
}
@media all and (min-width: 1200px) {
  .leaderboard-my-rank {
    width: 900px;
    margin: 0 auto;
  }
}

.leaderboard-grey-text {
  color: #b7b7b7;
}

.leaderboard-table-main_row {
  border-top: 1px solid #f5f5f5;
  padding: 0.8rem 0;
}

.leaderboard-table-row-rank {
  display: flex;
  justify-content: center;
  width: 2rem;
}

/* .makeStyles-paper-11 {
  width: calc(100% - 1.6rem) !important;
}

@media all and (min-width: 768px) {
  .makeStyles-paper-11 {
    width: calc(70% - 1.6rem) !important;
  }
} */
