.container__welcome {
  position: relative;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.content__welcome {
  width: 100%;
  height: auto;
}

.TopContentContainer__welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.TopLines__welcome {
  /* margin: 15px auto 0; */
  /* display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  right: 0;
  left: 0; */
  /* z-index: 1100; */
  /* margin: 0 auto; */
}
.TopLines__welcome img {
}

.above-logo-text__welcome {
  font-family: "High-School-Eur";
  color: white;
  /* text-align: center; */
  /* margin: 0 auto; */
  font-style: normal;
  font-size: 2em;
}

.mvp-logo__welcome {
  /* display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto; */
}

.below-logo-text__welcome {
  font-family: "High-School-Eur";
  color: white;
  /* text-align: center; */
  /* margin: 0 auto 75px; */
  font-style: normal;
  font-size: 2em;
}

.inside-below-logo-text__welcome {
  color: #79c001;
  font-size: 1.5em;
}

.welcome-items__welcome {
  padding: 0 2em;
}

.welcomeBtn__welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
}

.button__welcome {
  padding: 0.5rem 2rem;
  background: #0f57fb;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4);
  border-radius: 28px;
  color: white;
  font-size: 1.5em;
  margin-bottom: 2.6875rem;
}

.bottomLogo__welcome {
  /* position: absolute;
  bottom: 0;
  right: 0;
  left: 0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 10px;
}
@media all and (min-height: 800px) {
  .bottomLogo__welcome {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

@media all and (min-width: 768px) {
  .container__welcome {
    /* margin-left: 10px; */
    /* top: 55px; */
  }

  .content__welcome {
    /* height: calc(100% - 200px) !important; */
    width: 110%;
  }

  .TopContentContainer__welcome {
    margin-top: 100px;
  }

  .welcome-items__welcome {
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .above-logo-text__welcome {
    font-size: 2em;
  }

  .below-logo-text__welcome {
    font-size: 2em;
    margin: 0 auto;
  }

  .inside-below-logo-text__welcome {
    color: #79c001;
    font-size: 1.3em;
  }

  .bottomLogo__welcome {
    position: unset;
    margin-bottom: -30px;
  }
}

@media all and (min-width: 1350px) {
  .content__welcome {
    width: 75%;
  }

  .bottomLogo__welcome {
    /* width: 75%; */
  }
}
@media all and (min-width: 1500px) {
  .above-logo-text__welcome {
    font-size: 2.5em;
  }

  .below-logo-text__welcome {
    font-size: 2.5em;
  }

  .inside-below-logo-text__welcome {
    font-size: 1.5em;
  }
}
