.terms__RSbody {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 0;
  left: 0;
}

.terms__RSbody a {
  color: #334049;
  font-size: 0.75em;
  text-decoration-line: underline;
}

@media all and (min-width: 767px) {
  .terms__RSbody a {
    font-size: 1em;
  }
}
