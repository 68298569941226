.terms__TermsFooter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.terms__TermsFooter a {
  color: #ffffff;
  font-size: 0.75em;
  text-decoration-line: underline;
}
