.container__welcomeItem {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: white;
  margin: 10px auto;
  border: 1px solid #7db92a;
  backdrop-filter: blur(7px);
  border-radius: 2px 2px 20px 2px;
  background-color: rgba(49, 62, 71, 0.5);
  padding: 5px 0 7px 5px;
}

.indexNumberBox__welcomeItem {
  align-self: flex-start;
}

.indexContent__welcomeItem {
  background-color: #0f57fb;
  border-radius: 3px;
  padding: 8px 12px;
  font-size: 0.8em;
}

.icon__welcomeItem {
  height: 100%;
  width: 59px;
  position: relative;
  display: flex;
  margin-right: 20px;
}

.icon__welcomeItem img {
  /* height: 100px; */
  position: absolute;
  top: -20px;
  width: 70px;
}

.content__welcomeItem {
  width: 70%;
}

.title__welcomeItem {
  font-family: "High-School-Eur";
  color: #79c001;
  font-size: 1.2em;
}

.text__welcomeItem {
  color: #fff;
  font-size: 0.8em;
  width: 12em;
}

@media all and (min-width: 768px) {
  .container__welcomeItem {
    height: 100%;
    align-items: center;
    flex-direction: column;
  }
  .content__welcomeItem {
    width: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
  }

  .icon__welcomeItem {
    justify-content: center;
    margin-right: unset;
    margin-bottom: 10px;
  }
  .icon__welcomeItem img {
    top: -50px;
    /* width: 130px; */
  }

  .title__welcomeItem {
    margin-top: 10px;
    font-size: 1.8em;
  }

  .text__welcomeItem {
    font-size: 1em;
    height: 60px;
    width: 100%;
  }
}
