/* @keyframes Slide_Up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.fadedBackground__MobileShare {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 1030;
}


.closeBtn__MobileShare {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}

.title__MobileShare {
  margin-top: 1.7rem;
  font-weight: 700;
  font-size: 1.625rem;
  text-align: center;
  line-height: 1.906rem;
}

.text__MobileShare {
  padding: 1.25rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.mainContent__MobileShare {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.urlBox__MobileShare {
  text-align: center;
  width: 90%;
  
  padding: 0.938rem;
  color: #79c000;
  background-color: #f4f4f4;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 16px;
  text-decoration: underline;
}

.urlBox__MobileShare img {
  max-height: 100%;
  max-width: 100%;
} */

@keyframes Slide_Up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.fadedBackground__MobileShare {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 1030;
}

.container__MobileShare {
  position: relative;
  gap: 20px;
  animation: Slide_Up 0.3s ease-in;
  top: 0;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

.title__MobileShare {
  margin-top: -1.5rem;
  font-size: 1.15rem;

  text-align: center;
  padding: 0 10px;
}

.copy_link_box__MobileShare {
  display: flex;
  justify-content: space-between;
  gap: 0.3rem;
  align-items: center;
}

.url2Share__MobileShare {
  background-color: #f4f4f4;
  /* padding: 0.7rem 3rem; */
  border: 1px solid #c2c2c2;
  border-radius: 1.25rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* margin: 0 0.1rem; */
}

.url2Share__MobileShare a {
  color: #79c000;
  text-decoration: underline;
  margin: 0.7rem 0.4rem 0.7rem 1rem;

  text-align: left;
}

.url2Share__MobileShare a:hover {
  color: #79c000;
}

.copyBtn__MobileShare {
  /* width: 30%; */
  width: 6.25rem;
  height: 3rem;
  border: 0;
  padding: 0.7rem 2rem;
  border-radius: 1.25rem;
  color: white;
  background: linear-gradient(123.87deg, #353e4b 5.44%, #243b5f 92.75%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.exitBtn__MobileShare {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: transparent;
  color: rgba(53, 61, 73, 0.6);
  border: none;
}

.socialIcons_container__MobileShare {
  width: 100%;
  display: flex;
  justify-content: center;
}
