.container__SocialIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 40px;
}
.mobileContainer__SocialIcons {
  margin-top: 1rem;
  padding: 0.938rem;
  width: 70%;
  display: flex;
  height: 2.375rem;
  justify-content: space-between;
}

.iconWraper__SocialIcons {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(150, 150, 150, 0.15);
  border-radius: 0.75rem;
  padding: 0.4rem 1rem;
}

.socialIcon__SocialIcons {
  width: 30px;
  height: 30px;
}

.platform-name__SocialIcons {
  font-size: 0.75rem;
  color: #353d49;
  margin-top: 0.5rem;
  text-align: center;
}
