.desktop-header-container {
  display: flex;
  margin-top: 0;
  height: 85px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.desktop-header-text {
  padding-top: 30px;
  font-weight: bold;
  font-size: 22px;
}

.how-to-play-desktop {
  text-align: center;
  margin: auto;
  font-size: 12px;
  margin-top: 1.5em;
  font-weight: 700;
}
