.slick-slider {
  overflow: hidden;
}
* {
  -webkit-focus-ring-color: none;
  outline: none;
}

@font-face {
  font-family: "High-School";
  src: url("./fonts/High-School.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "High-School-Eur";
  src: url("./fonts/HighSchoolEURSans-Condensed-V2.1.ttf");
  font-weight: bold;
}
