/* tabs material ui */

/* selectet tab predictions */
.matches-specific .MuiTab-textColorPrimary.Mui-selected,
.MuiTab-textColorPrimary {
  color: #79c100 !important;
  font-weight: 550 !important;
  font-size: 0.9em !important;
  font-family: sans-serif !important;
  text-decoration: none !important;
}

/* non selected tab predictions */
.matches-specific .MuiTab-textColorPrimary {
  color: #c0c0c0 !important;
}

/* selectet tab accumulator */
.accumulator-specific .MuiTab-textColorPrimary.Mui-selected,
.MuiTab-textColorPrimary {
  color: #353d49 !important;
  font-weight: 550 !important;
  font-size: 0.9em !important;
  font-family: sans-serif !important;
  text-decoration: none !important;
}

/* non selected tab accumulator */

.accumulator-specific .MuiTab-textColorPrimary {
  color: rgba(53, 61, 73, 0.6) !important;
}

.tabs-container,
.accumulator-tabs {
  border-bottom: solid rgb(224, 224, 224) 1px;
  background-color: #333f48;
}

.tabs-container-light {
  background-color: white;
}

.leaderboard-specific .MuiTab-textColorPrimary {
  color: #adadad !important;
  font-weight: 600 !important;
}

.leaderboard-specific .MuiTab-textColorPrimary.Mui-selected {
  color: #79c000 !important;
  font-size: 0.9em !important;
  font-family: sans-serif !important;
  text-decoration: none !important;
}

.accumulator-tabs {
  background-color: white;
  color: black;
  border-radius: 10px;
}

.MuiTab-wrapper {
  text-transform: none;
}
